import axios from 'axios';

if (location.host.indexOf('dev.') === 0) {
  axios.defaults.baseURL = 'https://dev.api.stbit.org'
} else if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = 'https://api.stbit.org'
} else {
  axios.defaults.baseURL = 'http://localhost:89'
}

export default {
  async config() {
    return await axios.get('/config')
  },
}
