import axios from 'axios';
import Cookies from 'js-cookie'

if (location.host.indexOf('dev.') === 0) {
  axios.defaults.baseURL = 'https://dev.api.stbit.org'
} else if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = 'https://api.stbit.org'
} else {
  axios.defaults.baseURL = 'http://localhost:89'
}

const options = {
  headers: {
    'X-User-Token': Cookies.get('token')
  }
}

export default {
  async user() {
    if (options.headers['X-User-Token'] === '') {
      return
    }
    return await axios.get('/user', options)
  },
  async userLogin(email, password) {
    return await axios.post('/user/login', {
      email,
      password
    }, options)
  },
  async userLogout() {
    return await axios.post('/user/logout', {}, options)
  },
  async getUserEvent() {
    return await axios.get('/user/event', options)
  },
  async doneUserEvent(eventId) {
    return await axios.post('/user/event/' + eventId + '/done', null, options)
  },
  async update(fields) {
    return await axios.post('/user', {
      fields: fields
    }, options)
  },
}
