import axios from 'axios';
import Cookies from 'js-cookie'

if (location.host.indexOf('dev.') === 0) {
  axios.defaults.baseURL = 'https://dev.api.stbit.org'
} else if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = 'https://api.stbit.org'
} else {
  axios.defaults.baseURL = 'http://localhost:89'
}

const options = {
  headers: {
    'X-User-Token': Cookies.get('token'),
    'X-Merchant-Id': Cookies.get('merchantId'),
  }
}

export default {
  async getAccountList() {
    return await axios.get('/partner/account', options)
  },
  async getAccountTransactionList() {
    return await axios.get('/partner/account/transaction', options)
  },
  async getMethodList() {
    return await axios.get('/partner/method', options)
  },
  async getClientMethodList() {
    return await axios.get('/client/method', options)
  },
  async getSessionList() {
    return await axios.get('/partner/session', options)
  },
  async getOrderList() {
    return await axios.get('/partner/order', options)
  },
  async postOrder(methodId, type, currency, amount, credentials) {
    return await axios.post('/partner/merchant/order', {
      methodId, type, currency, amount, credentials
    }, options)
  },
  async postSessionMethod(methodId, amount, credentials) {
    return await axios.post('/partner/session/method', {
      methodId, amount, credentials
    }, options)
  },
}
