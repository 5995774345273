<template>
  <q-bar class="bg-primary text-white">
    <q-btn dense flat icon="verified" class="no-pointer-events" />
    <div class="text-weight-bold q-mr-sm">
      stbit
    </div>
    <q-space/>
    <div style="font-size: 13px;" class="q-mr-xs">{{ user.email }}</div>
    <router-link :to="{ name: 'user-logout' }" class="text-white">
      <q-btn dense flat icon="logout"/>
    </router-link>
  </q-bar>
  <div class="row">
    <div class="col">
      <SupportSessionOrder></SupportSessionOrder>
    </div>
  </div>
</template>

<script>
import apiCommon from '@/api/common';
import websocket from '@/websocket';
import Cookies from "js-cookie";

export default {
  name: 'SupportPage',
  data() {
    return {
      config: {
        exchangeRate: {
          in: [],
          out: []
        },
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    apiCommon.config().then((r) => {
      this.config = r.data.result
    });
    websocket.send({
      "action": "subscribe",
      "channel": "user",
      "channelId": this.$store.state.user.id,
      "token": Cookies.get('token'),
    })
  }
}
</script>

<script setup>
import SupportSessionOrder from "@/views/Support/SupportSessionOrder.vue"
</script>