<template>
  <div class="col">
    <q-table
        class="sticky-header-table sticky-last-column-table"
        flat bordered dense
        title="Deposit sessions"
        :rows="depositRows"
        :columns="columns"
        :loading="loading"
        row-key="id"
        :rows-per-page-options="[0]"
        hide-bottom
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            {{ col.label }}
          </q-th>
          <q-th></q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            {{ col.value }}
          </q-td>
          <q-td auto-width>
            <q-btn size="xs" color="accent" label="take"
                   @click="sessionOrderDialog=true; sessionId = props.row.id; updateMethodList('deposit', props.row.methodId)"
              />
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <div class="col">
    <q-table
        class="sticky-header-table sticky-last-column-table"
        flat bordered dense
        title="Withdraw sessions"
        :rows="withdrawRows"
        :columns="columns"
        :loading="loading"
        row-key="id"
        :rows-per-page-options="[0]"
        hide-bottom
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            {{ col.label }}
          </q-th>
          <q-th></q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            {{ col.value }}
          </q-td>
          <q-td auto-width>
            <q-btn size="xs" color="accent" label="take"
                   @click="sessionId = props.row.id; sessionOrder()"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="sessionOrderDialog">
    <q-card>
      <q-card-section class="row items-center">
        <q-select v-if="traderMethodLoading||traderMethodList.length>0"
                  outlined dense
                  style="min-width: 260px;"
                  :loading="traderMethodLoading"
                  v-model="traderMethod"
                  :options="traderMethodList"
                  @update:model-value="sessionOrder()"
                  label="Trader method"/>
        <q-banner v-if="traderMethodList.length===0 && !traderMethodLoading"
                  class="text-white bg-red" dense>
          <div>No trader methods available</div>
        </q-banner>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import api from '@/api/trader'
import websocket from '@/websocket'
import event from '@/event';

const columns = [
  { name: 'method', align: 'left', label: 'Method', field: 'method' },
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency' },
  { name: 'amount', align: 'right', label: 'Amount', field: 'amount', format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'uuid', align: 'left', label: 'UUID', field: 'uuid', style: 'font-size: 11px;' },
  { name: 'expired', align: 'right', label: 'Expired', field: 'expired' },
];

export default {
  setup () {
    const loading = ref(true)
    const depositRows = ref([])
    const withdrawRows = ref([])
    const traderMethod = ref(null)
    const traderMethodList = ref([])
    const traderMethodLoading = ref(false)
    const sessionOrderDialog = ref(false)
    const sessionId = ref(null)

    return {
      loading,
      columns,
      depositRows,
      withdrawRows,
      traderMethod,
      traderMethodList,
      traderMethodLoading,
      sessionOrderDialog,
      sessionId,
    }
  },
  mounted() {
    this.fetchSessionList()
    websocket.onmessage((e) => {
      let data = JSON.parse(e.data)
      switch (data.event) {
        case 'session.status.updated':
          this.fetchSessionList()
          break;
      }
    })
  },
  methods: {
    fetchSessionList() {
      api.getSessionList().then((r) => {
        this.loading = false
        this.depositRows = r.data.result.deposit
        this.withdrawRows = r.data.result.withdraw
      });
    },
    updateMethodList(type, methodId) {
      this.traderMethodList = []
      this.traderMethodLoading = true
      api.getTraderMethodList().then((r) => {
        this.traderMethodLoading = false
        let that = this
        r.data.result[type].map(function (method) {
          if (method.methodId === methodId) {
            that.traderMethodList.push({label: (method.name ? '[' + method.name + '] ' : '') + method.cardAddress, value: method.id})
          }
        })
        if (that.traderMethodList.length === 0) {
          this.sessionOrderDialog = false
          event.dispatch('addTraderMethod', {methodId: methodId})
        }
      });
    },
    sessionOrder() {
      this.sessionOrderDialog = false
      let traderMethod = null
      if (this.traderMethod) {
        traderMethod = this.traderMethod.value;
      }
      api.postSessionOrder(this.sessionId, traderMethod).then(() => {
        this.traderMethod = null
      });
    },
  }
}
</script>
