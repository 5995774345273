<template>
  <q-table
      class="sticky-header-table sticky-last-column-table"
      flat bordered dense
      title="Session orders"
      :rows="rows"
      :columns="columns"
      :loading="loading"
      row-key="id"
      :rows-per-page-options="[0]"
      hide-bottom
  >
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
        >
          {{ col.label }}
        </q-th>
        <q-th></q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
        >
          <div v-if="col.name === 'amount'">
            <q-badge :color="props.row.type === 'deposit' ? 'green-8' : 'red-8'" :label="col.value" />
          </div>
          <div v-if="col.name !== 'amount'">
            {{ col.value }}
          </div>
        </q-td>
        <q-td auto-width>
          <q-btn size="xs" color="accent"
                 @click="openSessionOrderDialog(props.row)"
                 label="open"/>
        </q-td>
      </q-tr>
    </template>
    <template v-slot:body-cell-amount="props">
      <q-td :props="props">
        <q-badge :color="props.row.type === 'deposit' ? 'green-8' : 'red-8'" :label="props.value" />
      </q-td>
    </template>
  </q-table>
  <q-dialog v-model="sessionOrderDialog">
    <q-card style="width: 600px;">
      <q-card-section class="row items-center q-pb-none q-mb-none">
          <div class="text-h6 text-primary">{{ capitalized(sessionOrder.type) }} <span class="text-bold text-h5">{{ sessionOrder.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) }} {{ sessionOrder.currency }}</span></div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section class="row items-center q-ma-none q-pb-none q-pt-none">
        <div class="q-mt-sm q-layout-container">
          Method:<q-chip :label="sessionOrder.method" size="sm" class="text-uppercase" square/>
          Status:<q-chip :label="sessionOrder.sessionStatus" size="sm" class="text-uppercase" square/>
          Expired at:<q-chip :label="sessionOrder.expiredAt" size="sm" class="text-uppercase" square/>
        </div>
        <div class="q-mt-sm q-layout-container" v-if="!['done', 'cancel', 'expired'].includes(sessionOrder.sessionStatus)">
          <q-btn-group>
            <q-btn color="green"
                   label="Send for check"
                   icon="check"
                   @click="sessionOrderCheck(sessionOrder.id)"
                   v-if="sessionOrder.type === 'withdraw' && sessionOrder.sessionStatus === 'pending'"/>
            <q-btn color="green"
                   label="I got money"
                   icon="check"
                   @click="sessionOrderDone(sessionOrder.id)"
                   v-if="sessionOrder.type === 'deposit' && ['check', 'pending'].includes(sessionOrder.sessionStatus)"/>
            <q-btn color="red"
                   label="Cancel"
                   icon="cancel"
                   @click="sessionOrderCancel(sessionOrder.id)"
                   v-if="sessionOrder.type === 'withdraw' && sessionOrder.sessionStatus === 'pending'"/>
          </q-btn-group>
        </div>
      </q-card-section>
      <q-card-section>
        <div class="q-pa-md bg-grey-3">
          <q-scroll-area class="q-px-xs row justify-center text-left" ref="chatArea"
                         style="width: 100%; height: 300px;" :visible="false"
          >
            <q-page-container class="q-ma-sm">
              <template v-for="chatMessage in chatMessageList" :key="chatMessage.id">
                <q-chat-message :text="[chatMessage.message]" :sent="chatMessage.sent" text-html>
                  <template v-slot:name v-if="!chatMessage.sent">{{ chatMessage.from }}</template>
                  <template v-slot:stamp>{{ chatMessage.timestamp }}</template>
                </q-chat-message>
              </template>
            </q-page-container>
          </q-scroll-area>
        </div>
        <q-form @submit="postSessionOrderChat" v-if="!['done', 'cancel', 'expired'].includes(sessionOrder.sessionStatus)">
          <q-input placeholder="Write a message.." color="primary"
                   label-color="primary"
                   autofocus dense autocomplete="false"
                   v-model="chatMessage"
          >
            <template v-slot:prepend>
              <q-icon name="attachment"
                      class="cursor-pointer"
                      color="primary"
                      @click="this.$refs.chatFile.pickFiles()"
              />
            </template>
            <template v-slot:append>
              <q-icon
                  name="send"
                  class="cursor-pointer"
                  :class="!this.chatMessage || !this.chatMessage.length ? 'disabled' : ''"
                  color="primary"
                  @click="postSessionOrderChat"/>
            </template>
          </q-input>
        </q-form>
        <q-file
            style="display: none;"
            ref="chatFile"
            v-model="chatFile"
        />
      </q-card-section>
      <q-inner-loading
          :showing="chatLoading"
          v-if="chatLoading"
          color="blue-10"
      />
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, watch } from 'vue'
import api from '@/api/trader'
import websocket from '@/websocket'

const columns = [
  { name: 'id', align: 'left', label: 'Id', field: 'id' },
  { name: 'sessionStatus', align: 'left', label: 'Status', field: 'sessionStatus' },
  { name: 'method', align: 'left', label: 'Method', field: 'method' },
  { name: 'credentials', align: 'left', label: 'Credentials', field: 'credentials' },
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency' },
  { name: 'amount', align: 'right', label: 'Amount', field: 'amount', format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'createdAt', align: 'left', label: 'Created', field: 'createdAt' },
];

export default {
  setup () {
    const rows = ref([])
    const loading = ref(true)
    const sessionOrderDialog = ref(false)
    const chatArea = ref(null)
    const chatLoading = ref(false)

    return {
      loading,
      columns,
      rows,
      chatArea,
      chatLoading,
      sessionOrderDialog,
    }
  },
  data() {
    let chatFile = ref(null)
    watch(chatFile, (file) => {
      this.uploadFile(file)
    });
    return {
      sessionOrder: ref(null),
      chatId: null,
      chatMessage: ref(null),
      chatMessageList: ref([]),
      chatMessageId: [],
      chatFile: chatFile,
      websocket: websocket,
    }
  },
  mounted() {
    this.fetchSessionOrderList()
    this.websocket.onmessage((e) => {
      let data = JSON.parse(e.data)
      switch (data.event) {
        case 'session.status.updated':
        case 'session.order.created':
        case 'session.order.status.updated':
          this.fetchSessionOrderList()
          if (this.sessionOrder && data.data.session && this.sessionOrder.sessionId === data.data.session.id) {
            this.sessionOrder.sessionStatus = data.data.session.status
          }
          break;
        case 'chat.message.created':
          if (this.chatMessageId[data.data.chatMessage.id]) {
            break
          }
          this.pushChatMessage(data.data.chatMessage, true)
          break
      }
    })
  },
  methods: {
    fetchSessionOrderList() {
      api.getSessionOrderList().then((r) => {
        this.loading = false
        this.rows = r.data.result.sessionOrderList
      });
    },
    sessionOrderDone(sessionOrderId) {
      if (!confirm('Done order?')) {
        return false
      }
      this.chatLoading = true
      api.postSessionOrderDone(sessionOrderId).then(() => {
        this.chatLoading = false
        this.sessionOrder.sessionStatus = 'done'
        this.fetchSessionOrderList()
      });
    },
    sessionOrderCheck(sessionOrderId) {
      if (!confirm('Send to check order?')) {
        return false
      }
      this.chatLoading = true
      api.postSessionOrderCheck(sessionOrderId).then(() => {
        this.chatLoading = false
        this.sessionOrder.sessionStatus = 'check'
        this.fetchSessionOrderList()
      });
    },
    sessionOrderCancel(sessionOrderId) {
      if (!confirm('Cancel order?')) {
        return false
      }
      this.chatLoading = true
      api.postSessionOrderCancel(sessionOrderId).then(() => {
        this.chatLoading = false
        this.sessionOrder.sessionStatus = 'cancel'
        this.fetchSessionOrderList()
      });
    },
    openSessionOrderDialog(sessionOrder) {
      this.chatLoading = true
      this.sessionOrderDialog = true
      this.chatMessageList = []
      this.chatMessageId = []
      this.sessionOrder = sessionOrder
      this.fetchSessionOrderChat()
    },
    fetchSessionOrderChat() {
      let needScroll = false
      api.getSessionOrderChat(this.sessionOrder.id).then((r) => {
        this.chatLoading = false
        let that = this
        this.chatId = r.data.result['chatId']
        r.data.result['messageList'].map(function(chatMessage) {
          if (that.chatMessageId[chatMessage.id]) {
            return
          }
          needScroll = true
          that.chatMessageId[chatMessage.id] = true
          that.pushChatMessage(chatMessage)
        })
        if (['pending', 'check'].includes(this.sessionOrder.sessionStatus)) {
          this.websocket.send({
            "action": "subscribe",
            "channel": "chat",
            "channelId": this.chatId,
          })
        }
      }).finally(() => {
        if (needScroll) {
          this.scrollChatArea()
        }
      })
    },
    pushChatMessage(chatMessage, needScroll = false) {
      if (!chatMessage.message) {
        return
      }
      let timestamp = new Date(chatMessage.timestamp * 1000)
      this.chatMessageList.push({
        id: chatMessage.id,
        message: this.prepareChatMessage(chatMessage.message),
        from: chatMessage.from,
        timestamp: timestamp.toLocaleDateString() + ' ' + timestamp.toLocaleTimeString(),
        sent: chatMessage.from === 'trader'
      })
      if (needScroll) {
        this.scrollChatArea()
      }
    },
    prepareChatMessage(message) {
      if (message.indexOf('https://d12l1bhpq6blvn.cloudfront.net') === 0) {
        message = '<a href="'+message+'" target="_blank"><img src="'+message+'"/></a>'
      }
      return message.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    scrollChatArea() {
      if (!this.chatArea) {
        return
      }
      setTimeout(() => {
        let scrollHeight = this.chatArea.getScrollTarget().scrollHeight
        this.chatArea.setScrollPosition('vertical', scrollHeight)
      }, 0);
    },
    postSessionOrderChat() {
      if (!this.chatMessage) {
        return
      }
      if (this.websocket) {
        let chatMessage = {
          "id": Math.floor(Math.random() * Date.now()),
          "from": "trader",
          "message": this.chatMessage,
          "timestamp": Date.now() / 1000,
        }
        this.chatMessageId[chatMessage.id] = true
        this.websocket.send({
          "action": "send",
          "channel": "chat",
          "channelId": this.chatId,
          "message": JSON.stringify({
            "event": "chat.message.created",
            "data": {
              "chatMessage": chatMessage
            }
          }),
        })
        this.pushChatMessage(chatMessage, true)
      }
      api.postSessionOrderChat(this.sessionOrder.id, this.chatMessage).then(() => {
        this.chatMessage = null
        if (!this.websocket) {
          this.fetchSessionOrderChat()
        }
      })
    },
    capitalized(name) {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);

      return capitalizedFirst + rest;
    },
    uploadFile(file) {
      this.chatLoading = true
      let fileData = new FormData()
      fileData.append('file', file)
      api.postUpload(this.chatId, file).then((r) => {
        this.chatLoading = false
        this.chatMessage = r.data.result.url
        this.postSessionOrderChat()
      })
    },
  }
}
</script>
