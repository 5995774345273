<template>
  <q-table
      class="sticky-header-table"
      flat bordered dense
      :rows="rows"
      :columns="columns"
      :loading="loading"
      row-key="id"
      :rows-per-page-options="[0]"
      hide-bottom
  >
    <template v-slot:top>
      <div class="q-table__control">
        <div class="col-2 q-table__title">Accounts</div>
      </div>
    </template>
  </q-table>
</template>

<script>
import { ref } from 'vue'
import api from '@/api/partner';
import websocket from '@/websocket';
import event from '@/event';

const columns = [
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency', sortable: true },
  { name: 'deposit', align: 'right', label: 'Deposit', field: 'deposit', sortable: true, format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'withdraw', align: 'right', label: 'Withdraw', field: 'withdraw', sortable: true, format: val => (val > 0 ? '-' : '') + val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'sessionDeposit', align: 'right', label: 'Session deposit', field: 'sessionDeposit', sortable: true, format: val =>  val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'sessionWithdraw', align: 'right', label: 'Session withdraw', field: 'sessionWithdraw', sortable: true, format: val => (val > 0 ? '-' : '') + val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'hold', align: 'right', label: 'Hold', field: 'hold', sortable: true, format: val => (val > 0 ? '-' : '') + val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'commission', align: 'right', label: 'Commission', field: 'commission', sortable: true, format: val => (val > 0 ? '-' : '') + val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'balance', align: 'right', label: 'Balance', field: 'balance', sortable: true, format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1), classes: 'text-bold' },
];

export default {
  computed: {
    event() {
      return event
    }
  },
  setup () {
    const rows = ref([])
    const loading = ref(true)

    return {
      loading,
      columns,
      rows,
    }
  },
  mounted() {
    this.fetchAccountList()
    websocket.onmessage((e) => {
      let data = JSON.parse(e.data)
      console.log(data)
      switch (data.event) {
        case 'merchant.order.created':
        case 'merchant.order.status.updated':
          this.fetchAccountList()
          break;
      }
    })
  },
  methods: {
    fetchAccountList() {
      api.getAccountList().then((r) => {
        this.loading = false
        this.rows = r.data.result.accountList
      });
    },
  }
}
</script>
