<template>
  <div class="q-mx-auto">
    <q-form @submit="onSubmit" class="q-mx-auto" style="max-width: 400px;">
      <h5 class="text-primary text-weight-medium q-mb-lg q-mt-none q-pt-lg">stbit / backoffice</h5>
      <q-input
          label="E-mail"
          v-model="email"
          autofocus
          class="bg-white q-mb-md"
          outlined
          borderless>
        <template v-slot:prepend>
          <q-icon name="email"/>
        </template>
      </q-input>
      <q-input
          type="password"
          label="Password"
          v-model="password"
          class="bg-white"
          outlined
          borderless>
        <template v-slot:prepend>
          <q-icon name="password"/>
        </template>
      </q-input>
      <q-btn
          type="submit"
          color="secondary"
          icon-right="login"
          label="Log in"
          no-caps
          class="full-width q-mt-md"
          :disabled="email.length < 7 || password.length < 5"
          size="lg"/>
    </q-form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import api from '@/api/user';
import Cookies from 'js-cookie'

const email = ref('')
const password = ref('')
const router = useRouter()

function onSubmit() {
  api.userLogin(email.value, password.value).then((r) => {
    Cookies.set('token', r.data.result.token)
    Cookies.set('traderId', r.data.result.traderId)
    Cookies.set('merchantId', r.data.result.merchantId)
    router.go(0)
  }).catch((e) => {
    alert(e.response.data.error)
  });
}
</script>