import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex';

import Main from '@/pages/Main.vue'
import User from '@/pages/User.vue'
import Trader from '@/pages/Trader.vue'
import Partner from '@/pages/Partner.vue'
import Operator from '@/pages/Operator.vue'
import Support from '@/pages/Support.vue'

const routes = [
    {
        path: '/',
        name: 'main',
        component: Main
    },
    {
        path: '/trader',
        name: 'trader',
        component: Trader
    },
    {
        path: '/partner',
        name: 'partner',
        component: Partner
    },
    {
        path: '/operator',
        name: 'operator',
        component: Operator
    },
    {
        path: '/support',
        name: 'support',
        component: Support
    },
    {
        path: '/user',
        name: 'user',
        component: User,
        children: [
            {
                path: 'login',
                name: 'user-login',
                component: User,
                meta: {
                    title: 'Log in',
                    noAuth: true
                },
            },
            {
                path: 'logout',
                name: 'user-logout',
                component: User,
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    strict: true,
    routes
})

router.beforeEach((to) => {
    const store = useStore()

    const isAuth = store.getters.isAuth
    const isRouteAuth = !('noAuth' in to.meta);

    if (isRouteAuth && !isAuth) {
        return { name: 'user-login' }
    }

    if (!isRouteAuth && isAuth) {
        return { name: 'main' }
    }

    document.title = (to.meta.title || to.name.toString()) + ' / stbit'
});

export default router
