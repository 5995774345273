import axios from 'axios';
import Cookies from 'js-cookie'

if (location.host.indexOf('dev.') === 0) {
  axios.defaults.baseURL = 'https://dev.api.stbit.org'
} else if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = 'https://api.stbit.org'
} else {
  axios.defaults.baseURL = 'http://localhost:89'
}

const options = {
  headers: {
    'X-User-Token': Cookies.get('token'),
  }
}

export default {
  async getSessionList() {
    return await axios.get('/operator/session', options)
  },
  async getSessionOrderList(filter) {
    return await axios.get('/operator/session/order?filter=' + filter, options)
  },
  async getMerchantOrderList() {
    return await axios.get('/operator/merchant/order', options)
  },
  async postMerchantOrderDone(merchantOrderId, comment) {
    return await axios.post('/operator/merchant/order/' + merchantOrderId + '/done', {
      comment
    }, options)
  },
  async postMerchantOrderCancel(merchantOrderId, comment) {
    return await axios.post('/operator/merchant/order/' + merchantOrderId + '/cancel', {
      comment
    }, options)
  },
  async getTraderOrderList() {
    return await axios.get('/operator/trader/order', options)
  },
  async postTraderOrderDone(traderOrderId, comment) {
    return await axios.post('/operator/trader/order/' + traderOrderId + '/done', {
      comment
    }, options)
  },
  async postTraderOrderCancel(traderOrderId, comment) {
    return await axios.post('/operator/trader/order/' + traderOrderId + '/cancel', {
      comment
    }, options)
  },
  async getTraderList() {
    return await axios.get('/operator/trader', options)
  },
  async getTraderAccountList() {
    return await axios.get('/operator/trader-account', options)
  },
  async getMerchantAccountList() {
    return await axios.get('/operator/merchant-account', options)
  },
  async postSessionOrderDone(sessionOrderId) {
    return await axios.post('/operator/session/order/' + sessionOrderId + '/done', {}, options)
  },
  async postSessionOrderCancel(sessionOrderId) {
    return await axios.post('/operator/session/order/' + sessionOrderId + '/cancel', {}, options)
  },
  async postSessionOrderAmount(sessionOrderId, amount) {
    return await axios.post('/operator/session/order/' + sessionOrderId + '/amount', {
      amount
    }, options)
  },
  async postSessionCancel(sessionId) {
    return await axios.post('/operator/session/' + sessionId + '/cancel', {}, options)
  },
  async postSessionOrderUpdateTrader(sessionOrderId, traderId, methodId) {
    return await axios.post('/operator/session/order/' + sessionOrderId + '/trader', {
      traderId,
      methodId
    }, options)
  },
}
