<template>
  <q-bar class="bg-primary text-white">
    <q-btn dense flat icon="verified" class="no-pointer-events" />
    <div class="text-weight-bold q-mr-sm">
      stbit
    </div>
    <q-space />
    <div style="position: relative" v-if="eventList.length > 0">
      <q-btn-dropdown unelevated square label="Notifications">
        <q-list separator style="max-width: 400px; max-height: 400px; min-width: 300px;">
          <template v-for="event in eventList" :key="event.id">
            <q-item clickable>
              <q-item-section top>
                <q-item-label lines="1">
                  <span class="text-weight-medium" v-if="event.event === 'chat.message.created'">New message</span>
                  <span class="text-weight-medium" v-else-if="event.event === 'session.status.updated'">Session: {{ event.data.session.type }} {{ event.data.session.amount }} {{ event.data.session.currency }}</span>
                  <span class="text-weight-medium" v-else-if="event.event === 'session.order.created'">New session order</span>
                  <span class="text-weight-medium" v-else-if="event.event === 'session.order.status.updated'">Session order: {{ event.data.sessionOrder.type }} {{ event.data.sessionOrder.amount }} {{ event.data.sessionOrder.currency }}</span>
                  <span class="text-weight-medium" v-else>{{ event.event }}</span>
                </q-item-label>
                <q-item-label caption lines="1">
                  <div v-if="event.event === 'chat.message.created'">
                    <span class="text-bold">{{ event.data.chatMessage.from }}</span>: {{ event.data.chatMessage.message }}
                  </div>
                  <div v-if="event.event === 'session.status.updated'">
                    Changed status from {{ event.data.fromStatus }} to <span class="text-bold">{{ event.data.toStatus }}</span>
                  </div>
                  <div v-if="event.event === 'session.order.created'">
                    {{ event.data.sessionOrder.type }} {{ event.data.sessionOrder.amount }} {{ event.data.sessionOrder.currency }}
                  </div>
                  <div v-if="event.event === 'session.order.status.updated'">
                    Changed status from {{ event.data.fromStatus }} to <span class="text-bold">{{ event.data.toStatus }}</span>
                  </div>
                </q-item-label>
                <q-item-label caption class="text-grey-6" style="font-size: 11px;">
                  {{ event.timestamp }}
                </q-item-label>
              </q-item-section>
              <q-item-section top side>
                <div class="text-grey-8 q-gutter-xs">
                  <q-btn class="gt-xs" size="14px" flat dense round icon="done" @click="doneEvent(event.id)"/>
                </div>
              </q-item-section>
            </q-item>
          </template>
        </q-list>
      </q-btn-dropdown>
      <q-badge color="red" rounded floating style="right: 15px; top:5px; font-size: 10px;">{{ eventList.length }}</q-badge>
    </div>
    <div style="font-size: 13px;" class="q-mr-xs">
      <q-toggle
          v-model="traderActive"
          label="TRAFFIC"
          color="green"
          @update:model-value="toggleTraffic()"
          left-label
      />
    </div>
    <div style="font-size: 13px;" class="q-mr-xs">{{ user.email }}</div>
    <router-link :to="{ name: 'user-logout' }" class="text-white">
      <q-btn dense flat icon="logout"/>
    </router-link>
  </q-bar>
  <div class="row">
    <div class="col">
      <TraderAccount></TraderAccount>
    </div>
    <div class="col">
      <TraderAccountTransaction></TraderAccountTransaction>
    </div>
  </div>
  <div class="row">
    <TraderSession></TraderSession>
  </div>
  <div class="row">
    <div class="col">
      <TraderMethod></TraderMethod>
    </div>
    <div class="col">
      <TraderSessionOrder></TraderSessionOrder>
    </div>
  </div>
  <div class="row">
    <TraderOrder></TraderOrder>
  </div>
</template>

<script>
import { ref } from 'vue'
import apiCommon from '@/api/common'
import apiUser from '@/api/user'
import websocket from '@/websocket'
import Cookies from 'js-cookie'

export default {
  name: 'TraderPage',
  data() {
    return {
      config: {
        exchangeRate: {
          in: [],
          out: []
        },
      },
      traderActive: ref(true),
      eventList: ref([]),
      websocket: websocket,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.traderActive = this.user.traderActive;
    apiCommon.config().then((r) => {
      this.config = r.data.result
    });
    websocket.send({
      "action": "subscribe",
      "channel": "user",
      "channelId": this.$store.state.user.id,
      "token": Cookies.get('token'),
    })
    websocket.send({
      "action": "subscribe",
      "channel": "role",
      "channelId": this.$store.state.user.role,
      "token": Cookies.get('token'),
    })
    this.websocket.onmessage((e) => {
      let data = JSON.parse(e.data)
      if (!data.id) {
        return
      }
      this.pushEvent(data)
    });
    this.fetchEventList()
  },
  methods: {
    fetchEventList() {
      apiUser.getUserEvent().then((r) => {
        let that = this
        that.eventList = [];
        r.data.result['eventList'].map(function(event) {
          that.pushEvent(event)
        })
      })
    },
    toggleTraffic() {
      apiUser.update({'traderActive': this.traderActive})
    },
    pushEvent(event) {
      let timestamp = new Date(event.timestamp * 1000)
      this.eventList.unshift({
        id: event.id,
        event: event.event,
        data: event.data,
        timestamp: timestamp.toLocaleDateString() + ' ' + timestamp.toLocaleTimeString(),
      })
    },
    doneEvent(eventId) {
      apiUser.doneUserEvent(eventId).then(() => {
        this.fetchEventList()
      });
    },
  }
}
</script>

<script setup>
import TraderAccount from '@/views/Trader/TraderAccount.vue'
import TraderAccountTransaction from '@/views/Trader/TraderAccountTransaction.vue'
import TraderSession from '@/views/Trader/TraderSession.vue'
import TraderSessionOrder from '@/views/Trader/TraderSessionOrder.vue'
import TraderMethod from '@/views/Trader/TraderMethod.vue'
import TraderOrder from '@/views/Trader/TraderOrder.vue'
</script>
