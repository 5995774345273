<template>
  <q-bar class="bg-primary text-white">
    <q-btn dense flat icon="verified" class="no-pointer-events" />
    <div class="text-weight-bold q-mr-sm">
      stbit
    </div>
    <q-space/>
    <div style="font-size: 13px;" class="q-mr-xs">{{ user.email }}</div>
    <router-link :to="{ name: 'user-logout' }" class="text-white">
      <q-btn dense flat icon="logout"/>
    </router-link>
  </q-bar>
  <div class="row">
    <div class="col">
      <OperatorSessionOrder></OperatorSessionOrder>
    </div>
  </div>
  <div class="row">
    <OperatorMerchantOrder></OperatorMerchantOrder>
  </div>
  <div class="row">
    <OperatorTraderOrder></OperatorTraderOrder>
  </div>
  <div class="row">
    <div class="col">
      <OperatorTraderAccount></OperatorTraderAccount>
    </div>
    <div class="col">
      <OperatorMerchantAccount></OperatorMerchantAccount>
    </div>
  </div>
</template>

<script>
import apiCommon from '@/api/common';
import websocket from '@/websocket';
import Cookies from "js-cookie";

export default {
  name: 'OperatorPage',
  data() {
    return {
      config: {
        exchangeRate: {
          in: [],
          out: []
        },
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    apiCommon.config().then((r) => {
      this.config = r.data.result
    });
    websocket.send({
      "action": "subscribe",
      "channel": "user",
      "channelId": this.$store.state.user.id,
      "token": Cookies.get('token'),
    })
  }
}
</script>

<script setup>
import OperatorMerchantOrder from "@/views/Operator/OperatorMerchantOrder.vue";
import OperatorMerchantAccount from "@/views/Operator/OperatorMerchantAccount.vue";
import OperatorTraderOrder from "@/views/Operator/OperatorTraderOrder.vue";
import OperatorTraderAccount from "@/views/Operator/OperatorTraderAccount.vue";
import OperatorSessionOrder from "@/views/Operator/OperatorSessionOrder.vue"
</script>