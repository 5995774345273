const listener = [];

export default {
  subscribe(event, f) {
    listener[event] = (e) => { f(e) }
  },
  dispatch(event, d) {
    listener[event](d)
  },
}
