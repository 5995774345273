let wsServer = 'wss://api.stbit.org/ws'
if (location.host.indexOf('dev.') === 0) {
  wsServer = 'wss://dev.ws.stbit.org'
} else if (process.env.NODE_ENV === 'development') {
  wsServer = 'ws://localhost:888'
}

let websocket = new WebSocket(wsServer)
websocket.onclose = () => {
  websocket = null
}

export default {
  onmessage(f) {
    if (!websocket) {
      return
    }
    websocket.addEventListener('message', (e) => { f(e) })
  },
  send(data) {
    this.sendWaitConnection(websocket, function() {
      websocket.send(JSON.stringify(data));
    });
  },
  sendWaitConnection(websocket, callback) {
    if (!websocket) {
      return
    }
    let that = this
    setTimeout(
      function() {
        if (websocket.readyState !== 1) {
          that.sendWaitConnection(websocket, callback)
        } else {
          callback()
        }
      }, 100)
  },
}
