<template>
  <q-table
      class="sticky-header-table"
      flat bordered dense
      title="Transactions"
      :rows="rows"
      :columns="columns"
      :loading="loading"
      row-key="id"
      :rows-per-page-options="[0]"
      hide-bottom
  >
    <template v-slot:body-cell-amount="props">
      <q-td :props="props">
        <q-badge :color="props.row.type === 'in' ? 'green-8' : 'red-8'" :label="props.value" />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import { ref } from 'vue'
import api from '@/api/trader'
import websocket from '@/websocket'

const columns = [
  { name: 'id', align: 'left', label: 'Id', field: 'id' },
  { name: 'dateAt', align: 'left', label: 'Created', field: 'dateAt', sortable: true },
  { name: 'operation', align: 'left', label: 'Operation', field: 'operation', sortable: true },
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency', sortable: true },
  { name: 'amount', align: 'right', label: 'Amount', field: 'amount', format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1), sortable: true },
  { name: 'session', align: 'left', label: 'Session', field: 'sessionUuid', format: val => !val ? '-' : val },
  { name: 'order', align: 'left', label: 'Order', field: 'orderId', format: val => !val ? '-' : val },
  { name: 'exchangeRate', align: 'right', label: 'Exchange rate', field: 'exchangeRate', format: val => !val ? '-' : val.toFixed(4) },
];

export default {
  setup () {
    const rows = ref([])
    const loading = ref(true)

    return {
      loading,
      columns,
      rows,
    }
  },
  mounted() {
    this.fetchAccountTransactionList()
    websocket.onmessage((e) => {
      let data = JSON.parse(e.data)
      switch (data.event) {
        case 'session.order.created':
        case 'session.order.status.updated':
        case 'trader.order.created':
        case 'trader.order.status.updated':
          this.fetchAccountTransactionList()
          break;
      }
    })
  },
  methods: {
    fetchAccountTransactionList() {
      api.getAccountTransactionList().then((r) => {
        this.loading = false
        this.rows = r.data.result.accountTransactionList
      });
    }
  }
}
</script>
