<template>
  <div class="col">
    <q-table
        class="sticky-header-table sticky-last-column-table"
        flat bordered dense
        title="Deposit orders"
        :rows="depositRows"
        :columns="columns"
        :loading="loading"
        row-key="id"
        :rows-per-page-options="[0]"
        hide-bottom
    >
      <template v-slot:top>
        <div class="q-table__control">
          <div class="col-2 q-table__title">Merchant deposit orders</div>
        </div>
      </template>
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            {{ col.label }}
          </q-th>
          <q-th></q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            <div v-if="col.name === 'status'">
              <q-badge :color="props.row.status === 'done' ? 'green-8' : (props.row.status === 'cancel' ? 'red-8' : 'gray-3')" :label="col.value" />
            </div>
            <div v-if="col.name !== 'status'">
              {{ col.value }}
            </div>
          </q-td>
          <q-td auto-width style="text-align: center">
            <q-btn size="xs" round color="grey-2 text-blue-10"
                   @click="doneMerchantOrder(props.row.id)"
                   icon="done" class="q-mr-xs" :disable="!['wait', 'cancel'].includes(props.row.status)"/>
            <q-btn size="xs" round color="grey-2 text-red-10"
                   @click="cancelMerchantOrder(props.row.id)"
                   icon="cancel" :disable="!['wait', 'done'].includes(props.row.status)"/>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <div class="col">
    <q-table
        class="sticky-header-table"
        flat bordered dense
        :rows="withdrawRows"
        :columns="columns"
        :loading="loading"
        row-key="id"
        :rows-per-page-options="[0]"
        hide-bottom
    >
      <template v-slot:top>
        <div class="q-table__control">
          <div class="col-2 q-table__title">Merchant withdraw orders</div>
        </div>
      </template>
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            {{ col.label }}
          </q-th>
          <q-th></q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            <div v-if="col.name === 'status'">
              <q-badge :color="props.row.status === 'done' ? 'green-8' : (props.row.status === 'cancel' ? 'red-8' : 'gray-3')" :label="col.value" />
            </div>
            <div v-if="col.name !== 'status'">
              {{ col.value }}
            </div>
          </q-td>
          <q-td auto-width style="text-align: center">
            <q-btn size="xs" round color="grey-2 text-blue-10"
                   @click="doneMerchantOrder(props.row.id)"
                   icon="done" class="q-mr-xs" :disable="!['wait', 'cancel'].includes(props.row.status)"/>
            <q-btn size="xs" round color="grey-2 text-red-10"
                   @click="cancelMerchantOrder(props.row.id)"
                   icon="cancel" :disable="!['wait', 'done'].includes(props.row.status)"/>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { ref } from 'vue'
import api from '@/api/operator';

const columns = [
  { name: 'id', align: 'left', label: 'Id', field: 'id' },
  { name: 'merchant', align: 'left', label: 'Merchant', field: 'merchant' },
  { name: 'status', align: 'left', label: 'Status', field: 'status' },
  { name: 'method', align: 'left', label: 'Method', field: 'method' },
  { name: 'amount', align: 'right', label: 'Amount', field: 'amount', format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency' },
  { name: 'comment', align: 'left', label: 'Comment', field: 'comment', format: val => !val ? '-' : val },
  { name: 'credentials', align: 'left', label: 'Credentials', field: 'credentials', format: val => !val ? '-' : val },
  { name: 'createdAt', align: 'left', label: 'Created', field: 'createdAt' },
];

export default {
  setup () {
    const depositRows = ref([])
    const withdrawRows = ref([])
    const loading = ref(true)
    const orderDialog = ref(false)
    const orderId = ref('')
    const orderType = ref('')
    const orderAmount = ref()

    return {
      loading,
      columns,
      depositRows,
      withdrawRows,
      orderId,
      orderDialog,
      orderType,
      orderAmount
    }
  },
  mounted() {
    this.fetchOrderList()
  },
  methods: {
    fetchOrderList() {
      api.getMerchantOrderList().then((r) => {
        this.loading = false
        this.depositRows = r.data.result.deposit
        this.withdrawRows = r.data.result.withdraw
      });
    },
    doneMerchantOrder(merchantOrderId) {
      if (!confirm('Done merchant order?')) {
        return false
      }
      const comment = prompt('Comment:')
      api.postMerchantOrderDone(merchantOrderId, comment).then(() => {
        this.fetchOrderList()
      });
    },
    cancelMerchantOrder(merchantOrderId) {
      if (!confirm('Cancel merchant order?')) {
        return false
      }
      const comment = prompt('Comment:')
      api.postMerchantOrderCancel(merchantOrderId, comment).then(() => {
        this.fetchOrderList()
      });
    },
  }
}
</script>
