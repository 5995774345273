import { createApp } from 'vue/dist/vue.esm-bundler'

import App from './layouts/App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarConfig from './config/quasar'
import store from './store'
import router from './router'
import api from "./api/user";

const app = createApp(App).use(Quasar, quasarConfig)

api.user().then((r) => {
  store.dispatch('setUser', r.data.result)
}).catch(() => {
}).finally(() => {
  app.use(store)
  app.use(router)

  app.mount('#app')
});
