import script from "./Trader.vue?vue&type=script&setup=true&lang=js"
export * from "./Trader.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBar from 'quasar/src/components/bar/QBar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBar,QBtn,QSpace,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QBadge,QToggle});
