<template>
  <q-table
      class="sticky-header-table"
      flat bordered dense
      :rows="depositRows"
      :columns="columns"
      :loading="loading"
      row-key="id"
      :rows-per-page-options="[0]"
      hide-bottom
  >
    <template v-slot:top>
      <div class="q-table__control">
        <div class="col-2 q-table__title">Deposit methods</div>
      </div>
      <q-space/>
      <q-btn size="sm" color="accent" label="add" @click="addTraderMethod()"/>
    </template>
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
        >
          {{ col.label }}
        </q-th>
        <q-th></q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
        >
          <div v-if="col.name === 'active'">
            <q-badge :color="col.value ? 'green-8' : 'red-8'" :label="col.value ? 'YES' : 'NO'" />
          </div>
          <div v-if="col.name !== 'active'">
            {{ col.value }}
          </div>
        </q-td>
        <q-td auto-width>
          <q-btn size="xs" round color="grey-2 text-blue-10" icon="edit" class="q-mr-xs" @click="editTraderMethod(props.row.id)"/>
          <q-btn size="xs" round color="grey-2 text-red-10" icon="delete" @click="deleteTraderMethod(props.row.id)"/>
        </q-td>
      </q-tr>
    </template>
  </q-table>
  <q-dialog v-model="traderMethodDialog">
    <q-card>
      <q-form
          @submit="traderMethodSubmit"
          class="q-ma-md"
          style="min-width: 400px;"
      >
        <q-toggle
            v-model="traderMethodActive"
            left-label
            label="Active"
        />
        <q-select
            v-if="!traderMethodId"
            filled
            outlined dense
            class="q-mb-sm"
            label="Method"
            v-model="traderMethodMethodId"
            :options="depositMethodList"
            map-options
        />
        <q-input
            filled
            outlined dense
            v-model="traderMethodCardNumber"
            class="q-mb-sm"
            label="Card number / phone"/>
        <q-input
            filled
            outlined dense
            v-model="traderMethodCardHolder"
            class="q-mb-sm"
            label="Card holder"/>
        <q-input
            filled
            outlined dense
            v-model="traderMethodCryptoAddress"
            label="Crypto address"/>
        <div class="q-mt-md">
          <q-btn label="Add method" type="submit" color="primary" v-if="!traderMethodId"/>
          <q-btn label="Update method" type="submit" color="primary" v-if="traderMethodId"/>
          <q-btn flat label="Cancel" color="primary" v-close-popup class="q-ml-sm"/>
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import api from '@/api/trader';
import event from '@/event'

const columns = [
  { name: 'method', align: 'left', label: 'Method', field: 'method', sortable: true },
  { name: 'active', align: 'left', label: 'Active', field: 'active', sortable: true },
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency', sortable: true },
  { name: 'cardAddress', align: 'left', label: 'Card / crypto', field: 'cardAddress', format: val => !val ? '-' : val },
];

export default {
  setup () {
    const depositRows = ref([])
    const withdrawRows = ref([])
    const loading = ref(true)
    const depositMethodList = ref([])
    const traderMethodList = []
    const traderMethodDialog = ref(false)
    const traderMethodId = ref(null)
    const traderMethodMethodId = ref(null)
    const traderMethodName = ref('')
    const traderMethodActive = ref(true)
    const traderMethodCardNumber = ref(null)
    const traderMethodCardHolder = ref(null)
    const traderMethodCryptoAddress = ref(null)

    return {
      loading,
      columns,
      depositRows,
      withdrawRows,
      depositMethodList,
      traderMethodList,
      traderMethodDialog,
      traderMethodId,
      traderMethodMethodId,
      traderMethodName,
      traderMethodActive,
      traderMethodCardNumber,
      traderMethodCardHolder,
      traderMethodCryptoAddress,
    }
  },
  mounted() {
    this.fetchTraderMethodList()
    this.fetchDepositMethodList()
    event.subscribe('addTraderMethod', (data) => {
      this.addTraderMethod()
      this.traderMethodMethodId = data.methodId
    })
  },
  methods: {
    fetchTraderMethodList() {
      api.getTraderMethodList().then((r) => {
        this.loading = false
        this.depositRows = r.data.result.deposit
        this.withdrawRows = r.data.result.withdraw
        let that = this
        r.data.result.deposit.map(function(traderMethod) {
          that.traderMethodList[traderMethod.id] = traderMethod
        })
      });
    },
    fetchDepositMethodList() {
      api.getClientMethodList().then((r) => {
        let that = this
        Object.entries(r.data.result['deposit']).map(function (currency) {
          currency[1].map(function(method) {
            that.depositMethodList.push({label: currency[0] + ' - ' + method.name, value: method.id})
          })
        })
      });
    },
    addTraderMethod() {
      this.traderMethodDialog = true
      this.traderMethodId = null
      this.traderMethodMethodId = null
      this.traderMethodName = null
      this.traderMethodActive = true
      this.traderMethodCardNumber = null
      this.traderMethodCardHolder = null
      this.traderMethodCryptoAddress = null
    },
    editTraderMethod(traderMethodId) {
      this.traderMethodDialog = true
      this.traderMethodId = traderMethodId
      let traderMethod = this.traderMethodList[traderMethodId]
      this.traderMethodMethodId = traderMethod.methodId
      this.traderMethodName = traderMethod.name
      this.traderMethodActive = traderMethod.active
      this.traderMethodCardNumber = traderMethod.cardNumber
      this.traderMethodCardHolder = traderMethod.cardHolder
      this.traderMethodCryptoAddress = traderMethod.cryptoAddress
    },
    deleteTraderMethod(traderMethodId) {
      if (!confirm('Delete deposit method?')) {
        return false
      }
      api.deleteTraderMethodList(traderMethodId).then(() => {
        this.fetchTraderMethodList()
      });
    },
    traderMethodSubmit() {
      this.traderMethodDialog = false
      let traderMethod = {
        "methodId": this.traderMethodMethodId
            ? (this.traderMethodMethodId.value ? this.traderMethodMethodId.value : this.traderMethodMethodId)
            : null,
        "name": this.traderMethodName,
        "active": this.traderMethodActive,
        "cardNumber": this.traderMethodCardNumber,
        "cardHolder": this.traderMethodCardHolder,
        "cryptoAddress": this.traderMethodCryptoAddress,
      }
      if (this.traderMethodId) {
        api.putTraderMethodList(this.traderMethodId, traderMethod).then(() => {
          this.fetchTraderMethodList()
        });
      } else {
        api.postTraderMethodList(traderMethod).then(() => {
          this.fetchTraderMethodList()
        });
      }
    },
  }
}
</script>
