<template>
  {{ store.state.user }}
</template>

<script>
export default {
  name: 'UserView',
}
</script>

<script setup>
import { useStore } from 'vuex';

const store = useStore()
</script>