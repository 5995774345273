import axios from 'axios';
import Cookies from 'js-cookie'

if (location.host.indexOf('dev.') === 0) {
  axios.defaults.baseURL = 'https://dev.api.stbit.org'
} else if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = 'https://api.stbit.org'
} else {
  axios.defaults.baseURL = 'http://localhost:89'
}

const options = {
  headers: {
    'X-User-Token': Cookies.get('token'),
    'X-Trader-Id': Cookies.get('traderId'),
  }
}

export default {
  async getAccountList() {
    return await axios.get('/trader/account', options)
  },
  async getAccountTransactionList() {
    return await axios.get('/trader/account/transaction', options)
  },
  async getSessionList() {
    return await axios.get('/trader/session', options)
  },
  async getSessionOrderList() {
    return await axios.get('/trader/session/order', options)
  },
  async postSessionOrder(sessionId, traderMethodId) {
    return await axios.post('/trader/session/' + sessionId + '/order', {
      traderMethodId
    }, options)
  },
  async postSessionOrderCheck(sessionOrderId) {
    return await axios.post('/trader/session/order/' + sessionOrderId + '/check', null, options)
  },
  async postSessionOrderDone(sessionOrderId) {
    return await axios.post('/trader/session/order/' + sessionOrderId + '/done', null, options)
  },
  async postSessionOrderCancel(sessionOrderId) {
    return await axios.post('/trader/session/order/' + sessionOrderId + '/cancel', null, options)
  },
  async getSessionOrderChat(sessionOrderId) {
    return await axios.get('/trader/session/order/' + sessionOrderId + '/chat', options)
  },
  async postSessionOrderChat(sessionOrderId, message) {
    return await axios.post('/trader/session/order/' + sessionOrderId + '/chat', {
      message
    }, options)
  },
  async getTraderMethodList() {
    return await axios.get('/trader/trader-method', options)
  },
  async postTraderMethodList(traderMethod) {
    return await axios.post('/trader/trader-method', traderMethod, options)
  },
  async putTraderMethodList(traderMethodId, traderMethod) {
    return await axios.put('/trader/trader-method/' + traderMethodId, traderMethod, options)
  },
  async deleteTraderMethodList(traderMethod) {
    return await axios.delete('/trader/trader-method/' + traderMethod, options)
  },
  async getOrderList() {
    return await axios.get('/trader/order', options)
  },
  async postOrder(methodId, type, currency, amount, credentials) {
    return await axios.post('/trader/order', {
      methodId, type, currency, amount, credentials
    }, options)
  },
  async getMethodList() {
    return await axios.get('/trader/method', options)
  },
  async getClientMethodList() {
    return await axios.get('/client/method', options)
  },
  async postUpload(chatId, file) {
    return await axios.post('/upload', {
      chatId,
      file
    }, { headers: {
        'Content-Type': 'multipart/form-data'
      }})
  }
}
