<template>
  <div class="col">
    <q-table
        class="sticky-header-table"
        flat bordered dense
        title="Deposit sessions"
        :rows="depositRows"
        :columns="columns"
        :loading="loading"
        row-key="id"
        :rows-per-page-options="[0]"
        hide-bottom
    >
      <template v-slot:top>
        <div class="q-table__control">
          <div class="col-2 q-table__title">Deposit sessions</div>
        </div>
        <q-space/>
        <q-btn size="sm" color="accent" label="new deposit session" @click="openPartnerSessionDialog('deposit')"/>
      </template>
      <template v-slot:body-cell-status="props">
        <q-td :props="props">
          <q-badge :color="props.row.status === 'done' ? 'green-8' : (['cancel', 'expired'].includes(props.row.status) ? 'red-8' : 'gray-3')" :label="props.value" />
        </q-td>
      </template>
    </q-table>
  </div>
  <div class="col">
    <q-table
        class="sticky-header-table"
        flat bordered dense
        title="Withdraw sessions"
        :rows="withdrawRows"
        :columns="columns"
        :loading="loading"
        row-key="id"
        :rows-per-page-options="[0]"
        hide-bottom
    >
      <template v-slot:top>
        <div class="q-table__control">
          <div class="col-2 q-table__title">Withdraw sessions</div>
        </div>
        <q-space/>
        <q-btn size="sm" color="accent" label="new withdraw session" @click="openPartnerSessionDialog('withdraw')"/>
      </template>
      <template v-slot:body-cell-status="props">
        <q-td :props="props">
          <q-badge :color="props.row.status === 'done' ? 'green-8' : (['cancel', 'expired'].includes(props.row.status) ? 'red-8' : 'gray-3')" :label="props.value" />
        </q-td>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="partnerSessionDialog">
    <q-card>
      <q-form
          @submit="partnerSessionSubmit"
          class="q-ma-md"
          style="min-width: 400px;"
      >
        <q-select
            filled
            outlined dense
            class="q-mb-sm"
            label="Account"
            v-model="currency"
            :options="currencyList"
            @update:model-value="fetchMethodList()"
        />
        <q-select
            v-if="currency !== null"
            filled
            outlined dense
            class="q-mb-sm"
            label="Method"
            v-model="partnerSessionMethodId"
            :options="methodList"
            map-options
        />
        <q-input
            v-if="currency !== null && partnerSessionMethodId !== null"
            filled
            outlined dense
            v-model="partnerSessionAmount"
            class="q-mb-sm"
            label="Amount"/>
        <q-input
            v-if="currency !== null && partnerSessionMethodId !== null && partnerSessionType === 'withdraw'"
            filled
            outlined dense
            v-model="partnerSessionCredentials"
            class="q-mb-sm"
            label="Credentials"/>
        <div class="q-mt-md" v-if="currency !== null && partnerSessionMethodId !== null">
          <q-btn label="Add session" type="submit" color="primary"/>
          <q-btn flat label="Cancel" color="primary" v-close-popup class="q-ml-sm"/>
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import api from '@/api/partner'
import websocket from '@/websocket'

const columns = [
  { name: 'uuid', align: 'left', label: 'UUID', field: 'uuid', style: 'font-size: 11px;' },
  { name: 'method', align: 'left', label: 'Method', field: 'method', format: val => !val ? '-' : val },
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency' },
  { name: 'amount', align: 'right', label: 'Amount', field: 'amount', format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1), sortable: true },
  { name: 'status', align: 'left', label: 'Status', field: 'status', sortable: true },
  { name: 'credentials', align: 'left', label: 'Credentials', field: 'credentials', format: val => !val ? '-' : val },
  { name: 'exchangeRate', align: 'right', label: 'Exchange rate', field: 'exchangeRate', format: val => !val ? '-' : val.toFixed(6) },
  { name: 'createdAt', align: 'left', label: 'Created', field: 'createdAt', sortable: true },
  { name: 'updatedAt', align: 'left', label: 'Updated', field: 'updatedAt' },
];

export default {
  setup () {
    const loading = ref(true)
    const depositRows = ref([])
    const withdrawRows = ref([])
    const currency = ref(null)
    const currencyList = ref([])
    const methodList = ref([])
    const partnerSessionDialog = ref(false)
    const partnerSessionType = ref(null)
    const partnerSessionMethodId = ref(null)
    const partnerSessionAmount = ref(null)
    const partnerSessionCredentials = ref(null)

    return {
      loading,
      columns,
      depositRows,
      withdrawRows,
      currency,
      currencyList,
      methodList,
      partnerSessionDialog,
      partnerSessionType,
      partnerSessionMethodId,
      partnerSessionAmount,
      partnerSessionCredentials,
    }
  },
  mounted() {
    this.fetchSessionList()
    websocket.onmessage((e) => {
      let data = JSON.parse(e.data)
      switch (data.event) {
        case 'session.status.updated':
          this.fetchSessionList()
          break
      }
    })
  },
  methods: {
    fetchSessionList() {
      api.getSessionList().then((r) => {
        this.loading = false
        this.depositRows = r.data.result.deposit
        this.withdrawRows = r.data.result.withdraw
      })
    },
    openPartnerSessionDialog(type) {
      this.partnerSessionDialog = true
      this.partnerSessionType = type
      this.currency = null
      this.methodList = []
      this.partnerSessionMethodId = null
      this.partnerSessionAmount = null
      this.partnerSessionCredentials = null
      this.currencyList = []

      api.getAccountList().then((r) => {
        let that = this
        r.data.result.accountList.map(function (account) {
          that.currencyList.push({label: account.currency + ' (' + account.balance + ')', value: account.currency})
        })
      })
    },
    partnerSessionSubmit() {
      api.postSessionMethod(
          this.partnerSessionMethodId.value,
          this.partnerSessionAmount * 1,
          this.partnerSessionCredentials
      ).then(() => {
        this.fetchSessionList()
        this.partnerSessionDialog = false
      }).catch((e) => {
        alert(e.response.data.error)
      })
    },
    fetchMethodList() {
      api.getClientMethodList().then((r) => {
        let that = this
        r.data.result[this.partnerSessionType][this.currency.value].map(function (method) {
          that.methodList.push({label: method.name, value: method.id})
        })
      })
    },
  }
}
</script>
