import { ref } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'

export function usePageRoute() {
  const route = useRoute()
  const pageRoute = ref(route.name)

  onBeforeRouteUpdate(async (to) => {
    pageRoute.value = to.name
  })

  return pageRoute
}
