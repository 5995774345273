import axios from 'axios';
import Cookies from 'js-cookie'

if (location.host.indexOf('dev.') === 0) {
  axios.defaults.baseURL = 'https://dev.api.stbit.org'
} else if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = 'https://api.stbit.org'
} else {
  axios.defaults.baseURL = 'http://localhost:89'
}

const options = {
  headers: {
    'X-User-Token': Cookies.get('token'),
  }
}

export default {
  async getSessionList() {
    return await axios.get('/support/session', options)
  },
  async getSessionOrderList(filter) {
    return await axios.get('/support/session/order?filter=' + filter, options)
  },
  async getTraderList() {
    return await axios.get('/support/trader', options)
  },
  async postSessionOrderDone(sessionOrderId) {
    return await axios.post('/support/session/order/' + sessionOrderId + '/done', {}, options)
  },
  async postSessionOrderCancel(sessionOrderId) {
    return await axios.post('/support/session/order/' + sessionOrderId + '/cancel', {}, options)
  },
  async postSessionOrderAmount(sessionOrderId, amount) {
    return await axios.post('/support/session/order/' + sessionOrderId + '/amount', {
      amount
    }, options)
  },
  async postSessionCancel(sessionId) {
    return await axios.post('/support/session/' + sessionId + '/cancel', {}, options)
  },
  async postSessionOrderUpdateTrader(sessionOrderId, traderId, methodId) {
    return await axios.post('/support/session/order/' + sessionOrderId + '/trader', {
      traderId,
      methodId
    }, options)
  },
}
