<template>
  <div></div>
</template>

<script>
export default {
  name: 'MainPage',
}
</script>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

router.push(store.state.user.role)
</script>
