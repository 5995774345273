<template>
  <div class="col">
    <q-table
        class="sticky-header-table"
        flat bordered dense
        title="Deposit orders"
        :rows="depositRows"
        :columns="columns"
        :loading="loading"
        row-key="id"
        :rows-per-page-options="[0]"
        hide-bottom
    >
      <template v-slot:top>
        <div class="q-table__control">
          <div class="col-2 q-table__title">Deposit orders</div>
        </div>
        <q-space/>
        <q-btn size="sm" color="accent" label="deposit" @click="openOrderDialog('deposit')"/>
      </template>
    </q-table>
  </div>
  <div class="col">
    <q-table
        class="sticky-header-table"
        flat bordered dense
        :rows="withdrawRows"
        :columns="columns"
        :loading="loading"
        row-key="id"
        hide-bottom
    >
      <template v-slot:top>
        <div class="q-table__control">
          <div class="col-2 q-table__title">Withdraw orders</div>
        </div>
        <q-space/>
        <q-btn size="sm" color="accent" label="withdraw" @click="openOrderDialog('withdraw')"/>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="orderDialog">
    <q-card>
      <q-form
          @submit="orderSubmit"
          class="q-ma-md"
          style="min-width: 400px;"
      >
        <q-select
            filled
            outlined dense
            class="q-mb-sm"
            label="Account"
            v-model="orderCurrency"
            :options="orderCurrencyList"
            @update:model-value="fetchMethodList()"
        />
        <q-select
            v-if="orderCurrency!=''"
            filled
            outlined dense
            class="q-mb-sm"
            label="Method"
            v-model="orderMethodId"
            :options="orderMethodList"
        />
        <q-input
            v-if="orderMethodId!=''"
            filled
            outlined dense
            v-model="orderAmount"
            class="q-mb-sm"
            label="Amount"/>
        <q-input
            v-if="orderMethodId!=''"
            filled
            outlined dense
            v-model="orderCredentials"
            class="q-mb-sm"
            label="Credentials"/>
        <div class="q-mt-md">
          <q-btn label="Add order" type="submit" color="primary"/>
          <q-btn flat label="Cancel" color="primary" v-close-popup class="q-ml-sm"/>
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import api from '@/api/partner';
import websocket from '@/websocket';

const columns = [
  { name: 'id', align: 'left', label: 'Id', field: 'id' },
  { name: 'status', align: 'left', label: 'Status', field: 'status' },
  { name: 'method', align: 'left', label: 'Method', field: 'method' },
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency' },
  { name: 'amount', align: 'right', label: 'Amount', field: 'amount', format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'credentials', align: 'left', label: 'Credentials', field: 'credentials', format: val => !val ? '-' : val },
  { name: 'createdAt', align: 'left', label: 'Created', field: 'createdAt' },
];

export default {
  setup () {
    const depositRows = ref([])
    const withdrawRows = ref([])
    const loading = ref(true)
    const orderDialog = ref(false)
    const orderType = ref('')
    const orderCurrency = ref('')
    const orderCurrencyList = ref([])
    const orderMethodId = ref('')
    const orderMethodList = ref([])
    const orderAmount = ref()
    const orderCredentials = ref()

    return {
      loading,
      columns,
      depositRows,
      withdrawRows,
      orderDialog,
      orderType,
      orderCurrency,
      orderCurrencyList,
      orderMethodId,
      orderMethodList,
      orderAmount,
      orderCredentials
    }
  },
  mounted() {
    this.fetchOrderList()
    websocket.onmessage((e) => {
      let data = JSON.parse(e.data)
      console.log(data)
      switch (data.event) {
        case 'merchant.order.created':
        case 'merchant.order.status.updated':
          this.fetchOrderList()
          break;
      }
    })
  },
  methods: {
    fetchOrderList() {
      api.getOrderList().then((r) => {
        this.loading = false
        this.depositRows = r.data.result.deposit
        this.withdrawRows = r.data.result.withdraw
      });
    },
    openOrderDialog(type) {
      this.orderDialog = true
      this.orderType = type
      this.orderCurrency = ''
      this.orderCurrencyList = []
      this.orderMethodId = ''
      this.orderMethodList = []
      this.orderAmount = ''
      this.orderCredentials = null

      api.getAccountList().then((r) => {
        let that = this
        r.data.result.accountList.map(function (account) {
          that.orderCurrencyList.push({label: account.currency + ' (' + account.balance + ')', value: account.currency})
        })
      });
    },
    fetchMethodList() {
      api.getMethodList().then((r) => {
        let that = this
        r.data.result[this.orderType][this.orderCurrency.value].map(function (method) {
            that.orderMethodList.push({label: method.name, value: method.id})
        })
      });
    },
    orderSubmit() {
      api.postOrder(
          this.orderMethodId.value,
          this.orderType,
          this.orderCurrency.value,
          this.orderAmount * 1,
          this.orderCredentials
      ).then(() => {
        this.fetchOrderList()
      }).catch((e) => {
        alert(e.response.data.error)
      }).finally(() => {
        this.orderDialog = false
      });
    }
  }
}
</script>
