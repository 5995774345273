<template>
  <div></div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import api from '@/api/user';
import Cookies from 'js-cookie';

const store = useStore()
const router = useRouter()

api.userLogout().then(() => {
  Cookies.set('token', '')
  store.state.user.id = null
  router.push({ name: 'user-login' })
})
</script>
