import script from "./Support.vue?vue&type=script&setup=true&lang=js"
export * from "./Support.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBar from 'quasar/src/components/bar/QBar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBar,QBtn,QSpace});
