
import '../styles/quasar.sass'
import lang from 'quasar/lang/en-US.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'

export default {
  config: {},
  plugins: {},
  lang: lang
}
