<template>
  <q-table
      class="sticky-header-table sticky-last-column-table"
      flat bordered dense
      title="Session orders"
      :rows="depositRows"
      :columns="columns"
      :loading="loading"
      row-key="id"
      :rows-per-page-options="[0]"
      hide-bottom
      style="height: 250px;"
  >
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
        >
          {{ col.label }}
        </q-th>
        <q-th></q-th>
      </q-tr>
    </template>
    <template v-slot:top>
      <div class="q-table__control">
        <div class="col-2 q-table__title">Deposits</div>
      </div>
      <q-space/>
      <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
        >
          <div v-if="col.name === 'amount'">
            <q-badge :color="props.row.type === 'deposit' ? 'green-8' : 'red-8'" :label="col.value" />
          </div>
          <div v-if="col.name === 'orderAmount'">
            <q-badge :color="props.row.type === 'deposit' && props.row.amount <= props.row.orderAmount ? 'green-8' : 'red-8'" :label="col.value" />
          </div>
          <div v-if="col.name === 'sessionStatus'">
            <q-badge :color="props.row.sessionStatus === 'done' ? 'green-8' : (['cancel', 'expired'].includes(props.row.sessionStatus) ? 'red-8' : 'gray-3')" :label="col.value" />
          </div>
          <div v-if="col.name !== 'amount' && col.name !== 'orderAmount' && col.name !== 'sessionStatus'">
            {{ col.value }}
          </div>
        </q-td>
        <q-td auto-width>
          <q-btn size="xs" color="accent"
                 @click="openSessionOrderDialog(props.row)"
                 label="open"/>
        </q-td>
      </q-tr>
    </template>
  </q-table>
  <q-table
      class="sticky-header-table sticky-last-column-table"
      flat bordered dense
      title="Session orders"
      :rows="withdrawRows"
      :columns="columns"
      :loading="loading"
      row-key="id"
      :rows-per-page-options="[0]"
      hide-bottom
      style="height: 250px;"
  >
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
        >
          {{ col.label }}
        </q-th>
        <q-th></q-th>
      </q-tr>
    </template>
    <template v-slot:top>
      <div class="q-table__control">
        <div class="col-2 q-table__title">Withdraws</div>
      </div>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
        >
          <div v-if="col.name === 'amount'">
            <q-badge :color="props.row.type === 'deposit' ? 'green-8' : 'red-8'" :label="col.value" />
          </div>
          <div v-if="col.name === 'orderAmount'">
            <q-badge :color="props.row.type === 'withdraw' && props.row.amount > props.row.orderAmount ? 'green-8' : 'red-8'" :label="col.value" />
          </div>
          <div v-if="col.name === 'sessionStatus'">
            <q-badge :color="props.row.sessionStatus === 'done' ? 'green-8' : (['cancel', 'expired'].includes(props.row.sessionStatus) ? 'red-8' : 'gray-3')" :label="col.value" />
          </div>
          <div v-if="col.name !== 'amount' && col.name !== 'orderAmount' && col.name !== 'sessionStatus'">
            {{ col.value }}
          </div>
        </q-td>
        <q-td auto-width>
          <q-btn size="xs" color="accent"
                 @click="openSessionOrderDialog(props.row)"
                 label="open"/>
        </q-td>
      </q-tr>
    </template>
  </q-table>
  <q-dialog v-model="sessionOrderDialog">
    <q-card style="width: 600px;">
      <q-card-section class="row items-center q-pb-none q-mb-none">
        <div class="text-h6 text-primary">{{ capitalized(sessionOrder.type) }} <span class="text-bold text-h5">{{ sessionOrder.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) }} {{ sessionOrder.currency }}</span></div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section class="row items-center q-ma-none q-pb-none q-pt-none">
        <div class="q-mt-sm q-layout-container">
          <span v-if="sessionOrder.id">
          Method:<q-chip :label="sessionOrder.method" size="sm" class="text-uppercase" square/>
          </span>
          Status:<q-chip :label="sessionOrder.sessionStatus" size="sm" class="text-uppercase" square/>
          Expired at:<q-chip :label="sessionOrder.expiredAt" size="sm" class="text-uppercase" square/>
        </div>
        <div v-if="sessionOrder.id && sessionOrder.status !== 'done'">
          <q-input
              filled
              outlined dense
              v-model="orderAmount"
              class="q-mb-sm"
              label="Order amount"/>
          <q-btn color="yellow-8"
                 size="small"
                 dense
                 label="Update amount"
                 icon="edit"
                 @click="updateSessionOrderAmount()"/>
        </div>
        <div v-if="sessionOrder.id && sessionOrder.type === 'withdraw' && sessionOrder.sessionStatus === 'pending'" class="q-mb-md q-mt-sm">
          <q-select
                    outlined dense
                    style="min-width: 260px;"
                    v-model="sessionOrderTraderId"
                    :options="traderList"
                    @update:model-value="updateSessionOrderTrader()"
                    map-options
                    label="Trader"/>
        </div>
        <div class="q-mt-sm q-layout-container q-mb-md">
          <q-btn-group v-if="sessionOrder.id || sessionOrder.sessionStatus !== 'cancel'">
            <q-btn color="green"
                   label="Done"
                   icon="done"
                   @click="doneSessionOrder()"
                   v-if="sessionOrder.id && sessionOrder.sessionStatus !== 'done'"/>
            <q-btn color="red-8"
                   label="Cancel"
                   icon="cancel"
                   @click="cancelSessionOrder()"
                   v-if="sessionOrder.id && sessionOrder.sessionStatus !== 'cancel'"/>
            <q-btn color="red-8"
                   label="Cancel"
                   icon="cancel"
                   @click="cancelSession()"
                   v-if="!sessionOrder.id && sessionOrder.sessionStatus !== 'cancel'"/>
          </q-btn-group>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, watch } from 'vue'
import api from '@/api/operator'
import websocket from '@/websocket'

const columns = [
  { name: 'id', align: 'left', label: 'Id', field: 'id', format: val => !val ? '-' : val },
  { name: 'sessionId', align: 'left', label: 'Session Id', field: 'sessionId' },
  { name: 'merchant', align: 'left', label: 'Merchant', field: 'merchant' },
  { name: 'trader', align: 'left', label: 'Trader', field: 'trader', format: val => !val ? '-' : val },
  { name: 'uuid', align: 'left', label: 'Uuid', field: 'uuid' },
  { name: 'sessionStatus', align: 'left', label: 'Status', field: 'sessionStatus' },
  { name: 'method', align: 'left', label: 'Method', field: 'method', format: val => !val ? '-' : val },
  { name: 'credentials', align: 'left', label: 'Credentials', field: 'credentials' },
  { name: 'currency', align: 'left', label: 'Currency', field: 'currency' },
  { name: 'amount', align: 'right', label: 'Amount', field: 'amount', format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'orderAmount', align: 'right', label: 'Order Amount', field: 'orderAmount', format: val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).substring(1) },
  { name: 'createdAt', align: 'left', label: 'Created', field: 'createdAt' },
  { name: 'updatedAt', align: 'left', label: 'Updated', field: 'updatedAt' },
];

export default {
  setup () {
    const depositRows = ref([])
    const withdrawRows = ref([])
    const loading = ref(true)
    const sessionOrderDialog = ref(false)
    const sessionOrderTraderId = ref(null)
    const traderList = ref([])
    const orderAmount = ref()

    return {
      loading,
      columns,
      depositRows,
      withdrawRows,
      sessionOrderDialog,
      sessionOrderTraderId,
      traderList,
      orderAmount,
    }
  },
  data() {
    const filter = ref(null)
    watch(filter, () => {
      this.fetchSessionOrderList()
    });
    return {
      sessionOrder: ref(null),
      chatId: null,
      chatMessage: ref(null),
      chatMessageList: ref([]),
      chatMessageId: [],
      websocket: websocket,
      filter: filter,
    }
  },
  mounted() {
    this.fetchSessionOrderList()
    api.getTraderList().then((r) => {
      let that = this
      r.data.result['traderList'].map(function(trader) {
        that.traderList.push({label: trader.email, value: trader.id})
      })
    });
  },
  methods: {
    fetchSessionOrderList() {
      api.getSessionOrderList(this.filter ? this.filter : '').then((r) => {
        this.loading = false
        this.depositRows = r.data.result.deposit
        this.withdrawRows = r.data.result.withdraw
      });
    },
    openSessionOrderDialog(sessionOrder) {
      this.sessionOrderDialog = true
      this.sessionOrderTraderId = sessionOrder.traderId
      this.sessionOrder = sessionOrder
      this.orderAmount = sessionOrder.amount
    },
    capitalized(name) {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);

      return capitalizedFirst + rest;
    },
    doneSessionOrder() {
      if (!confirm('Done session order?')) {
        return false
      }
      api.postSessionOrderDone(this.sessionOrder.id).then(() => {
        this.sessionOrderDialog = false
        this.fetchSessionOrderList()
      });
    },
    cancelSessionOrder() {
      if (!confirm('Cancel session order?')) {
        return false
      }
      api.postSessionOrderCancel(this.sessionOrder.id).then(() => {
        this.sessionOrderDialog = false
        this.fetchSessionOrderList()
      });
    },
    updateSessionOrderAmount() {
      if (!confirm('Update session order amount?')) {
        return false
      }
      api.postSessionOrderAmount(this.sessionOrder.id, this.orderAmount * 1).then(() => {
        this.sessionOrderDialog = false
        this.fetchSessionOrderList()
      });
    },
    cancelSession() {
      if (!confirm('Cancel session?')) {
        return false
      }
      api.postSessionCancel(this.sessionOrder.sessionId).then(() => {
        this.sessionOrderDialog = false
        this.fetchSessionOrderList()
      });
    },
    updateSessionOrderTrader() {
      api.postSessionOrderUpdateTrader(
          this.sessionOrder.id,
          this.sessionOrderTraderId.value,
          this.sessionOrder.methodId
      ).then(() => {
        this.sessionOrderDialog = false
        this.fetchSessionOrderList()
      });
    },
  }
}
</script>
