<template>
  <User v-if="pageRoute === 'user'"></User>
  <UserLogin v-if="pageRoute === 'user-login'"></UserLogin>
  <UserLogout v-if="pageRoute === 'user-logout'"></UserLogout>
</template>

<script setup>
import User from '@/views/User/User.vue'
import UserLogin from '@/views/User/UserLogin.vue'
import UserLogout from '@/views/User/UserLogout.vue'

import { usePageRoute } from '@/shared/page-route'
const pageRoute = usePageRoute()
</script>

<script>
export default {
  name: 'UserPage',
}
</script>
